<template>
  <div class="service w-full h-full bg-blue-950 absolute text-[#222222] flex flex-col gap-4 items-center justify-center">
        <!-- hCaptcha component -->
        <vue-hcaptcha 
      sitekey="9ba44256-3702-41f0-8ab2-618e9a40e7f0" 
      @verify="onCaptchaVerify" 
      ref="captcha" 
    />
    
    <form class="scale-75 sm:scale-100 flex flex-col justify-center items-center bg-white w-[300px] rounded pt-4 pb-3">
      <p class="font-semibold text-center px-4" :class="{
        'text-red-600': successorno === 'Invalid credentials' || successorno === 'Error logging out',
        'text-green-600': successorno === 'Logged in successfully' || successorno === 'Logged out'
      }">
        {{ successorno }}
      </p>

      <h3 class="text-xl font-bold font-[roboto] admintitle py-4">Admin login</h3>
      <input id="username" v-model="email" type="text" placeholder="Username"
        class="border-2 text-black rounded-md p-1 m-3 w-60 bg-[#EEEEEE]" />
      <input id="password" v-model="password" type="password" placeholder="Password"
        class="border-2 text-black rounded-md p-1 mb-2 w-60 bg-[#EEEEEE]" />

      <div class="mr-auto ml-8">
        <input id="reveal" @click="revealpass" type="checkbox" class="mr-2 border-2 my-2 border-[#333333] rounded-md" />
        <label for="reveal" class="text-black text-sm font-[roboto]">Reveal password</label>
      </div>

      <!-- Updated button click handler to trigger hCaptcha -->
      <button type="button" @click="onLoginClick"
        class="bg-[#69688e] hover:bg-[#44435b] text-white rounded-md p-2 w-60 duration-200 mt-8"
        id="loginbutton">Login</button>
      
      <button type="button" @click="returnhome"
        class="bg-transparent border-2 hover:bg-[#f2f2f2] text-black text-sm rounded-md p-1 m-2 w-52 duration-200"
        id="returnhomebutton">Return home</button>
    </form>
  </div>
</template>


<script setup>
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { supabase } from '../clients/supabase';
import VueHcaptcha from '@hcaptcha/vue3-hcaptcha';
import emailjs from 'emailjs-com';

const email = ref("");
const password = ref("");
const successorno = ref("");
const captchaToken = ref('');
const captcha = ref(null);
const $router = useRouter();

function onCaptchaVerify(token) {
  captchaToken.value = token;
  login();
}

function sendEmail(ip, userAgent, time, screenSize, country, countryCode) {
  emailjs.init('mUIcDTkk26yYMvc6r');

  var templateParams = {
    ip: ip,
    userAgent: userAgent,
    time: time,
    screenSize: screenSize,
    country: country,
    countryCode: countryCode
  };

  /**
  * 
  * @param {string} ip The user's IP address.
  * @param {string} userAgent The user's user agent string.
  * @param {string} time The time the request was made.
  * @param {string} screenSize The user's screen size.
  * @param {string} country The user's country.
  * @param {string} countryCode The user's country code.
  */
  emailjs.send('service_vjde5hm', 'template_dew0khs', templateParams).then(
    (response) => {
      console.log('SUCCESS!', response.status, response.text);
    },
    (error) => {
      console.log('FAILED...', error);
    }
  );
}

async function getCountry(ip) {
  try {
    const response = await fetch(`https://ipapi.co/${ip}/json/`);
    const data = await response.json();

    return {
      country: data.country_name,
      countryCode: data.country_code
    };
  } catch (error) {
    console.error('Error fetching country data:', error);
    return { country: null, countryCode: null };
  }
}

async function getUser(next) {
  let localUser = await supabase.auth.getSession();
  console.log(localUser.data.session);

  if (localUser.data.session == null) {
    try {
      let ip = await fetch('https://api.ipify.org?format=json')
        .then(response => response.json())
        .then(data => data.ip);

      let { country, countryCode } = await getCountry(ip);

      sendEmail(
        ip,
        navigator.userAgent,
        new Date().toLocaleString(),
        window.screen.width + 'x' + window.screen.height,
        country || 'Unknown',
        countryCode || 'Unknown'
      );
    } catch (error) {
      console.error('Error fetching IP or sending email:', error);
    }
  } else {
    next();
  }
}

// Handler for Login button click
function onLoginClick() {
  // Reset any previous captcha tokens
  captchaToken.value = '';
  
  // Execute hCaptcha to prompt the user
  if (captcha.value) {
    captcha.value.execute(); // Triggers hCaptcha verification
  } else {
    console.error("hCaptcha component not found.");
  }
}

async function login() {
  if (!captchaToken.value) {
    successorno.value = "Captcha verification failed. Please try again.";
    return;
  }

  const { data, error } = await supabase.auth.signInWithPassword({
    email: email.value,
    password: password.value,
    options: {
      captchaToken: captchaToken.value
    }
  });

  if (error) {
    // Handle error (e.g., invalid credentials)
    successorno.value = "Invalid credentials";

    // Optionally, reset hCaptcha to allow the user to retry
    if (captcha.value) {
      captcha.value.reset();
    }

    // Implement your existing error handling logic
    getUser();

    const timer = 5;
    const loginbutton = document.getElementById("loginbutton");
    loginbutton.disabled = true;
    loginbutton.style.backgroundColor = "#333333";

    for (let i = 0; i < timer; i++) {
      setTimeout(() => {
        loginbutton.innerHTML = `Try again in ${timer - i} seconds`;
      }, i * 1000);
    }

    setTimeout(() => {
      loginbutton.value = "";
      loginbutton.disabled = false;
      loginbutton.style.backgroundColor = "#69688e";
      loginbutton.innerHTML = "Login";
    }, timer * 1000);
  } else {
    // Handle successful login
    successorno.value = "Logged in successfully";
    email.value = "";
    password.value = "";

    const admintitle = document.querySelector(".admintitle");
    const timer = 3;
    for (let i = 0; i < timer; i++) {
      setTimeout(() => {
        admintitle.innerHTML = `Redirecting in ${timer - i}`;
      }, i * 1000);
    }

    setTimeout(() => {
      $router.push({ name: "container" });
    }, timer * 1000);
  }
}

function returnhome() {
  $router.push({ name: "home" })
}

async function logout() {
  const { error } = await supabase.auth.signOut()

  if (error) {
    successorno.value = "Error logging out"
  } else {
    successorno.value = "Logged out"
  }
}

function revealpass() {
  const password = document.getElementById("password")
  password.type === "password" ? password.type = "text" : password.type = "password"
}

onMounted(() => {
  logout();

  document.addEventListener("keydown", (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      login();
    }
  });
});
</script>
