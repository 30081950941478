<template>
  <div class="absolute size-full text-white containerbackground">
    <section class="flex items-center text-xs rounded p-2 text-white justify-center gap-2">
      <button @click="update_next_match" class="bg-white/20 px-4 py-1 rounded hover:opacity-80 duration-200">Update next
        match</button>
      <button @click="update_news_db"
        class="bg-white/20 px-4 py-1 rounded hover:opacity-80 duration-200 updatecontent">Update news</button>
      <button @click="clear_db" class="bg-white/20 px-4 py-1 rounded hover:opacity-80 duration-200">Clear
        database</button>
      <button class="bg-white/20 duration-200 px-4 rounded py-1 hover:opacity-80" @click="returnhome">Return
        home</button>
    </section>

    <section class="w-full overflow-y-scroll h-40 mx-auto newsblock">
    </section>

    <section
      class="w-full hidden gap-2 md:flex fixed max-w-4xl text-white bg-white/10 border-white/10 border-2 rounded-t-lg md:rounded-lg p-2 bottom-2 left-1/2 transform -translate-x-1/2">

      <!-- Match Edit Section -->
      <section class="flex flex-col items-start w-full sm:w-1/2 space-y-2 p-6 border-r-2 border-white/30">
        <h2 class="text-sm font-semibold uppercase mb-4">Edit Next Match</h2>

        <input id="match1id" v-model="match1id" placeholder="Team 1"
          class="w-full p-3 border border-white/10 rounded-lg"></input>
        <input id="match2id" v-model="match2id" placeholder="Team 2"
          class="w-full p-3 border border-white/10 rounded-lg"></input>
        <input id="dateid" v-model="dateid" placeholder="Date"
          class="w-full p-3 border border-white/10 rounded-lg"></input>
        <input id="locationid" v-model="locationid" placeholder="Location"
          class="w-full p-3 border-2 border-white/10 rounded-lg"></input>
      </section>

      <!-- Add News Section -->
      <section class="flex flex-col items-start w-full sm:w-1/2 space-y-2 p-6 relative">
        <h2 class="text-sm font-semibold uppercase mb-4">Add News</h2>

        <input id="newsheading" v-model="newsheading" placeholder="News Headline"
          class="w-full p-3 border border-white/10 rounded-lg"></input>
        <input id="newspara" v-model="newspara" placeholder="News Description"
          class="w-full p-3 border border-white/10 rounded-lg"></input>
        <input id="newsdate" v-model="newsdate" placeholder="News Date"
          class="w-full p-3 border-2 border-white/10 rounded-lg"></input>
        <!-- <select v-if="!customimagecheck" id="imageurl" v-model="imageurl"
          class="w-full bg-white/20 pr-3 pl-1 py-1 text-sm border-2 border-white/10 rounded">
          <option disabled value="">Select an Image URL</option>
          <option value="https://github.com/tasfc/news-images/blob/main/news1.jpeg?raw=true">Image 1</option>
          <option value="https://github.com/tasfc/news-images/blob/main/news2.jpeg?raw=true">Image 2</option>
          <option value="https://github.com/tasfc/news-images/blob/main/news3.jpeg?raw=true">Image 3</option>
          <option value="https://github.com/tasfc/news-images/blob/main/news4.jpeg?raw=true">Image 4</option>
        </select> -->

        <input v-if="!customimagecheck" class="w-full p-3 border-2 border-white/10 rounded-lg bg-white/20"
          name="imageurl" v-model="imageurl" placeholder="Image URL here" id="">
        </input>

        <div class="flex items-center gap-2">
          <input disabled type="checkbox" id="customimagecheck" v-model="customimagecheck"
            class="w-4 h-4 border-2 border-white/10 bg-white/10 rounded" @click="customimagecheck = !customimagecheck">

          <label for="customimagecheck" class="text-sm line-through">Custom image</label>
        </div>

        <button @click="add_news"
          class="rounded duration-200 py-0 px-10 text-sm border-2 border-white/10 ml-auto bg-white/30 hover:bg-white/20 text-white">Add</button>
      </section>
    </section>

    <section class="md:hidden block">
      <Swiper :modules="modules" :slides-per-view="1.1" :space-between="30"
        class="bg-white/10 absolute bottom-0 w-full rounded-t-lg p-4">
        <swiper-slide class="flex flex-col items-start w-full sm:w-1/2 space-y-2 p-6 pt-2 border-r-2 border-white/20">
          <h2 class="text-lg font-semibold text-white">Edit Next Match</h2>

          <input id="match1id" v-model="match1id" placeholder="Team 1"
            class="w-full p-3 border-2 border-white/10 rounded-lg"></input>
          <input id="match2id" v-model="match2id" placeholder="Team 2"
            class="w-full p-3 border-2 border-white/10 rounded-lg"></input>
          <input id="dateid" v-model="dateid" placeholder="Date"
            class="w-full p-3 border-2 border-white/10 rounded-lg"></input>
          <input id="locationid" v-model="locationid" placeholder="Location"
            class="w-full p-3 border-2 border-white/10 rounded-lg"></input>
        </swiper-slide>

        <swiper-slide class="flex flex-col items-start w-full sm:w-1/2 px-6 pt-2 space-y-2 relative">
          <h2 class="text-lg font-semibold text-white uppercase">Add News</h2>

          <input id="newsheading" v-model="newsheading" placeholder="News Headline"
            class="w-full p-3 border-2 border-white/10 rounded-lg"></input>
          <input id="newspara" v-model="newspara" placeholder="News Description"
            class="w-full p-3 border-2 border-white/10 rounded-lg"></input>
          <input id="newsdate" v-model="newsdate" placeholder="News Date"
            class="w-full p-3 border-2 border-white/10 rounded-lg"></input>
          <select id="imageurl" v-model="imageurl"
            class="w-full bg-white/20 pr-3 pl-1 py-1 text-sm border-2 border-white/10 rounded">
            <option disabled value="">Select an Image URL</option>
            <option value="https://example.com/image1.jpg">Image 1</option>
            <option value="https://example.com/image2.jpg">Image 2</option>
            <option value="https://example.com/image3.jpg">Image 3</option>
          </select>


          <button @click="add_news"
            class="rounded duration-200 py-0 px-10 text-sm border-2 border-white/10 ml-auto bg-white/30 hover:bg-white/20 text-white">Add</button>
        </swiper-slide>
      </Swiper>
    </section>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import '@vuepic/vue-datepicker/dist/main.css';
import { supabase } from '../clients/supabase';

let match1id = ref(''),
  match2id = ref(''),
  locationid = ref(''),
  dateid = ref(''),
  newsheading = ref(''),
  newspara = ref(''),
  newsdate = ref(''),
  imageurl = ref(''),
  match1Value = ref(''),
  match2Value = ref(''),
  dateValue = ref(''),
  locationValue = ref(''),
  newsheadingValue = ref(''),
  newsparaValue = ref(''),
  imageurlValue = ref(''),
  newsdateValue = ref(''),
  addedNewsAmount = ref(0),
  customimagecheck = ref(false),
  headingnames = ref([]),
  readmore = ref(false),
  timeout = ref(5000);
// Swiper js
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/effect-cards';
import 'swiper/css/effect-fade';
import 'swiper/css/pagination';
import 'swiper/css/effect-coverflow';
import { EffectCards, EffectFade, Pagination, Autoplay, EffectCoverflow } from 'swiper/modules';

const modules = [EffectCards, EffectFade, Pagination, Autoplay, EffectCoverflow];

async function logout() {
  supabase.auth.signOut();
  $router.push('/');
}

async function clear_db() {
  if (confirm('Warning, this is a destructive action. Whilst this is safe and does not delete the database, it will remove all added values by you.')) {
    // delete all rows in the news_table apart from the one with the id 21
    const { data, error } = await supabase
      .from('news_table')
      .delete()
      .neq('id', 21);

    if (error) {
      console.error('Error deleting data:', error);
      return;
    }

    // delete all values in the id 22 row of the database
    const { data2, error2 } = await supabase
      .from('tas_database')
      .update({
        match_1: '',
        match_2: '',
        date: '',
        location: '',
      })
      .eq('id', 22);

    if (error2) {
      alert('Error updating database:', error2);
      return;
    }

    getdb();

    const updatecontent = document.querySelector('.updatecontent');
    updatecontent.disabled = false;
    updatecontent.style.opacity = 1;

    alert("Cleared database successfully");
  } else {
  }
}

async function update_next_match() {
  const { data, error } = await supabase
    .from('tas_database')
    .update({
      match_1: match1id.value,
      match_2: match2id.value,
      date: dateid.value,
      location: locationid.value,
    })
    .eq('id', 22);

  if (error) {
    alert('Error updating database:', error);
    return;
  } else {
    alert('Sucessfully updated next match in database')
  }
  // console.log('Updated database:', data);
  getdb();
}

async function update_news_db() {
  const AddedNews = document.querySelectorAll('.AddedNews');

  let newsData = {};

  for (let i = 0; i < AddedNews.length; i++) {
    const newsheadingId = `newsheading${i + 1}`;
    const newsheadingElement = document.getElementById(newsheadingId);
    if (!newsheadingElement) {
      // console.log(`Element with ID ${newsheadingId} not found.`);
      continue;
    }
    newsData[newsheadingId] = newsheadingElement.textContent;

    const newsparaId = `newspara${i + 1}`;
    const newsparaElement = document.getElementById(newsparaId);
    if (!newsparaElement) {
      // console.log(`Element with ID ${newsparaId} not found.`);
      continue;
    }
    newsData[newsparaId] = newsparaElement.textContent;

    const newsdateId = `newsdate${i + 1}`;
    const newsdateElement = document.getElementById(newsdateId);
    if (!newsdateElement) {
      // console.log(`Element with ID ${newsdateId} not found.`);
      continue;
    }
    newsData[newsdateId] = newsdateElement.textContent;

    // console.log(newsheadingId, newsparaId, newsdateId);

    const imageurlId = `imageurl${i + 1}`;
    const imageurlElement = document.getElementById(imageurlId);
    if (!imageurlElement) {
      // console.log(`Element with ID ${imageurlId} not found.`);
      continue;
    }
    newsData[imageurlId] = imageurlElement.textContent;

    const { data, error } = await supabase
      .from('news_table')
      .insert([
        {
          news_heading: newsData[newsheadingId],
          news_para: newsData[newsparaId],
          news_date: newsData[newsdateId],
          image_url: newsData[imageurlId],
        }
      ]);
  }

  // checkdatabase();

  alert("Sucessfully updated news in the database");
}

async function savedata() {
  getdb("getinfo");
}

function add_news() {
  addedNewsAmount.value++;

  const newsblock = document.querySelector('.newsblock'),
    newnews = document.createElement('div');

  newnews.classList.add('AddedNews', 'text-xs', 'sm:w-2/3', 'w-[95%]', 'text-white', 'overflow-hidden', 'mx-auto', 'mt-1', 'h-8', 'bg-white/20', 'flex', 'items-center', 'pl-4', 'rounded-full', 'border-2', 'border-white/10');
  newsblock.appendChild(newnews);
  newnews.id = `AddedNews${addedNewsAmount.value}`;

  const newnewsheadingText = newsheading.value,
    existingHeadings = document.querySelectorAll('h2');

  for (let i = 0; i < existingHeadings.length; i++) {
    if (existingHeadings[i].textContent === newnewsheadingText) {
      alert('This news heading already exists');
      newnews.remove();

      return;
    }
  }

  if (existingHeadings.length == 9) {
    alert('You can only add 5 news items. If you require more, please contact me.');
    newnews.remove();

    return;
  }

  const newnewsheading = document.createElement('h2');
  newnewsheading.textContent = newnewsheadingText;
  newnewsheading.classList.add('w-40');
  newnewsheading.id = `newsheading${addedNewsAmount.value}`;
  newnews.appendChild(newnewsheading);

  const newnewspara = document.createElement('p');
  newnewspara.textContent = newspara.value;
  newnewspara.classList.add('opacity-0');
  newnews.appendChild(newnewspara);
  newnewspara.id = `newspara${addedNewsAmount.value}`;

  const newnewsdate = document.createElement('p');
  newnewsdate.textContent = newsdate.value;
  newnewsdate.classList.add('opacity-0');
  newnews.appendChild(newnewsdate);
  newnewsdate.id = `newsdate${addedNewsAmount.value}`;

  const newnewsbutton = document.createElement('button');
  newnewsbutton.classList.add('ml-auto', 'mr-6');
  newnewsbutton.innerHTML = 'X';
  newnews.appendChild(newnewsbutton);
  newnewsbutton.id = `newsbutton${addedNewsAmount.value}`;

  const newimageurl = document.createElement('p');
  newimageurl.textContent = imageurl.value;
  newimageurl.classList.add('opacity-0');
  newnews.appendChild(newimageurl);
  newimageurl.id = `imageurl${addedNewsAmount.value}`;

  newnewsbutton.addEventListener('click', () => {
    newnews.remove();
  });
}

async function getNewsTable() {
  const { data, error } = await supabase
    .from('news_table')
    .select('*')
    .eq('id', 21);

  if (error) {
    console.error('Error fetching data:', error);
    return;
  }

  const rowData = data[0];

  if (!rowData) {
    console.error('No data found for id 21');
    return;
  }

  // assign newnewsheading the entire row of news_headings
  newsheadingValue.value = rowData.news_heading || '';
  newsparaValue.value = rowData.news_para || '';
  newsdateValue.value = rowData.news_date || '';
  imageurlValue.value = rowData.image_url || '';
}

async function getdb(info) {
  getNewsTable();

  const { data, error } = await supabase
    .from('tas_database')
    .select('*')
    .eq('id', 22);

  if (error) {
    console.error('Error fetching data:', error);
    return;
  }

  const rowData = data[0];

  if (!rowData) {
    console.error('No data found for id 22');
    return;
  }

  // console.log('Fetched data:', rowData);

  match1Value.value = rowData.match_1 || '';
  match2Value.value = rowData.match_2 || '';
  dateValue.value = rowData.date || '';
  locationValue.value = rowData.location || '';

  if (info === 'getinfo') {
    const dataarray = [
      match1id.value,
      match2id.value,
      dateid.value,
      locationid.value,
    ];

    const jsondata = JSON.stringify(dataarray),
      blob = new Blob([jsondata], { type: 'application/json' }),
      url = URL.createObjectURL(blob),
      a = document.createElement('a');
    a.href = url;
    a.download = 'data.json';
    a.click();
  }
}

async function checkamount() {
  const { data, error } = await supabase
    .from('news_table')
    .select('news_heading');

  if (error) {
    console.error('Error fetching data:', error);
    return;
  }

  const rowData = data;

  if (!rowData) {
    console.error('No data found for id 21');
    return;
  }

  let newsamount = 0;
  for (let i = 0; i < rowData.length; i++) {
    newsamount++;
  }

  const updatecontent = document.querySelector('.updatecontent');

  if (newsamount >= 5) {
    // alert("You have reached the maximum amount of news items (5). Please clear the database then you can add more.");

    updatecontent.disabled = true;
    updatecontent.style.opacity = 0.5;
    updatecontent.classList.remove('hover:bg-white/10');
  } else {
    updatecontent.disabled = false;
    updatecontent.style.opacity = 1;
    updatecontent.classList.add('hover:bg-white/10');
  }
}

onMounted(() => {
  getdb();
  checkamount();
});

const $router = useRouter();

function returnhome() {
  $router.push({ name: "home" })
}
</script>

<style scoped lang="scss">
input {
  @apply resize-none rounded px-2 py-2 text-white bg-white/10 overflow-hidden md:overflow-auto text-xs md:text-base;
}

.containerbackground {
  background: linear-gradient(45deg, #336085, #287076, #5b1e5e, #6d226c);
  animation: gradientAnimation 20s infinite;
  background-size: 600% 600%;
}

.newsblock {
  &::-webkit-scrollbar {
    @apply w-[10px];
  }

  &::-webkit-scrollbar-track {
    @apply bg-[#f1f1f100];
  }

  &::-webkit-scrollbar-thumb {
    @apply bg-white rounded-[20px];
  }
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}
</style>